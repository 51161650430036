<template>
  <div style="">
    <span style="color: rgba(175, 181, 190);">{{ result }}</span>
  </div>
</template>
<script>
import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import debounce from 'lodash.debounce';
import { getNameByEmail } from '@/utils/users';
import workflowModule from '@/store/modules/workflowModule';

export default {
  components: {

  },

  data() {
    return {
      selectedTags: [],
      wellTags: [],
      cancelBeforeStart: true,
      availableTags: [],
      highlightAllOnFocus: true,
      value: [],
      tagID: '',
      objectID: '',
      result: '',
      fieldName: '',
      attributeID: null,
      dataLoaded: false,
    };
  },

  async beforeMount() {
    const objectID = this.params.data.Well;
    this.objectID = objectID;
    const fieldName = this.params.colDef.colId;
    this.fieldName = fieldName;

    const data =  await workflowModule.getLatestDeferrmentValueForWell({nodeIdList: [objectID]})
   
    if(data.NodeID){
      this.result = data.Value
    }
    //  this.result
    // console.log(data);
  },

  async created() {

  },

  beforeUnmount() {

  },

  isPopup() {
    return true;
  },

  methods: {

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>;

<style scoped>
.customUI >>> .multiselect__tags {
    background: #283751;
    border-color: transparent;
    max-height: 35px;
    padding: 4px;
}

.customUI >>> .multiselect__select {
  display: none;
}

.customUI >>> .multiselect__tag {
  background: rgb(61,153,170);
}

.customUI >>> .multiselect__content-wrapper{
  position: fixed;
}

.customUI >>> .multiselect__option--highlight {
  background:rgb(61,153,170);
}

.customUI >>> .multiselect__tag-icon:after{
  background:rgb(61,153,170);
}

.customUI >>> i.multiselect__tag-icon{
  background:rgb(61,153,170);
}

.customUI >>> input {
  background: #283751;
  color: white;
}

.customUI >>> .multiselect__option--highlight:after{
  background:rgb(61,153,170);
  display: none;
}
.customUI >>> .multiselect__content-wrapper {
  position: fixed;

    position: fixed;
    z-index: 99999 !important;
    height: 150px !important;
  top: 50px;
  width: 300px;

}

.customUI >>> .multiselect--active {
  z-index: 10;
}
</style>
